<template>
    <div ref="prev" class="p-0" :style="mySize">
        <div id="bg-prev" :style="myStyles">
            <p class="pointer" @click="modalEdit = !modalEdit">{{x}}_{{y}}</p>
            <b-modal v-model="modalEdit" @ok="handleOk">
                <p>You pressed item {{comp.i}}</p>
                <b-form-checkbox v-model="remove"
                                 name="checkbox-remove"
                                 value="yes"
                                 unchecked-value="no">
                    Remove this component
                </b-form-checkbox>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import { bus } from '@/main.js';
    export default {
        props: ['dbid', 'itemid', 'comp'],
        data() {
            return {
                x: 0,
                y: 0,
                modalEdit: false,
                selected: '',
                remove: 'no'
            }
        },
        computed: {
            myStyles() {
                return {
                    width: '100%',
                    height: '100%',
                    'background-color': '#dddddd',
                    'border-style': 'solid',
                    'border-width': '1px',
                    display: 'flex',
                    'align-items': 'center',
                    'justify-content': 'center'
                }
            },
            mySize() {
                return {
                    width: '100%',
                    height: this.y + 'px'
                }
            }
        },
        mounted() {
            this.getWindowSize();
            this.$nextTick(() => {
                window.addEventListener('resize', () => {
                    this.$nextTick(() => {
                        this.getWindowSize();
                    });
                });
            })
        },
        created() {
            bus.$on('itemResized', (newSize) => {
                console.log(newSize)
                if (newSize.i == this.itemid) {
                    this.x = newSize.w
                    this.y = newSize.h
                }
            })
        },
        methods: {
            getWindowSize() {
                this.x = this.$refs.prev.clientWidth;
                this.y = this.$refs.prev.clientHeight;
            },
            handleOk() {
                if (this.remove == 'yes') {
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid
                    }
                    this.remove = 'no'
                    this.$store.commit('deleteComp', payload);
                }
            }
        }
    };
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>